<template>
  <div class="home">

    <div class="auth-wrap">

      <h2>Login</h2>

      <form @submit="sign_in" class="form" method="post">

        <div class="form-wrap form-wrap-first">
          <label for="email">E-Mail</label>
          <input v-model="user.email" class="form-input" type="email" name="email" id="email">
        </div>

        <div class="form-wrap form-wrap-last">
          <label for="password">Passwort</label>
          <input v-model="user.password" class="form-input" type="password" name="password" id="password">
        </div>

        <p>
          <input type="submit" value="Einloggen" class="button button-orange button-100">
        </p>
        <p>
          <router-link to="/auth/sign_up" class="button button-grey button-100">Registrierung</router-link>
        </p>

      </form>

    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'home',
  data () {
    return {
      user: {},
    }
  },
  methods: {
    sign_in: function (e) {
      e.preventDefault();

      axios.post(process.env.VUE_APP_BASE_API+'/v1/auth/sign_in', {
        email: this.user.email,
        password: this.user.password
      }, { headers: {} })
      .then(response => {
        console.log(response);
        localStorage.setItem('intime_token', response.headers['authorization']);
        this.$store.commit('addToken', response.headers['authorization']);
        this.$router.push('/account/overview');
      })
      .catch(error => {
        console.log(error);
        this.$notify({
          group: 'alert',
          type: 'error',
          title: 'Login fehlgeschlagen',
          text: 'Bitte versuchen Sie es erneut.'
        });
      })

    },
  },
  mounted () {}
}
</script>

<style lang="scss">

  .auth-wrap {
    padding: 20px;
    width: 500px;
    margin: auto;
    text-align: center;

    h2 {
      font-weight: 400;
      font-size: 34px;
    }
  }


</style>
